.UiInput-module_ui-input_7Pm5P__Main {
  flex-direction: column;
  width: 100%;
  display: flex;
  position: relative;
}

.UiInput-module_ui-input--error_y2m84__Main .UiInput-module_ui-input__input_6JYLR__Main {
  border-color: var(--input-error);
}

.UiInput-module_ui-input--disabled_9PPaC__Main .UiInput-module_ui-input__input_6JYLR__Main, .UiInput-module_ui-input--disabled_9PPaC__Main:hover .UiInput-module_ui-input__input_6JYLR__Main, .UiInput-module_ui-input--disabled_9PPaC__Main:focus .UiInput-module_ui-input__input_6JYLR__Main {
  background-color: var(--disabled-bg);
  border-color: var(--disabled-bg);
  color: var(--disabled-text);
}

.UiInput-module_ui-input--disabled_9PPaC__Main .UiInput-module_ui-input__input_6JYLR__Main::placeholder, .UiInput-module_ui-input--disabled_9PPaC__Main:hover .UiInput-module_ui-input__input_6JYLR__Main::placeholder, .UiInput-module_ui-input--disabled_9PPaC__Main:focus .UiInput-module_ui-input__input_6JYLR__Main::placeholder, .UiInput-module_ui-input--disabled_9PPaC__Main .UiInput-module_ui-input__label-text_FAMYg__Main, .UiInput-module_ui-input--disabled_9PPaC__Main:hover .UiInput-module_ui-input__label-text_FAMYg__Main, .UiInput-module_ui-input--disabled_9PPaC__Main:focus .UiInput-module_ui-input__label-text_FAMYg__Main {
  color: var(--disabled-text);
}

.UiInput-module_ui-input--with-icon_Eb-wk__Main .UiInput-module_ui-input__input_6JYLR__Main {
  padding: 5px 10px 5px 40px;
}

.UiInput-module_ui-input--search_H6m2Q__Main .UiInput-module_ui-input__input_6JYLR__Main {
  background-color: var(--bg-component);
  border: none;
  height: 30px;
  padding: 5px 30px 5px 10px;
}

.UiInput-module_ui-input__label_8BCri__Main {
  color: var(--input-text-empty);
  flex-direction: column;
  display: flex;
  position: relative;
}

.UiInput-module_ui-input__input_6JYLR__Main {
  background-color: var(--input-bg);
  border: 1px solid var(--input-stroke);
  height: 40px;
  font-family: var(--roboto-font);
  letter-spacing: .15px;
  color: var(--input-text-full);
  border-radius: 10px;
  padding: 10px 15px;
  font-size: 14px;
  font-style: normal;
  font-weight: 380;
  line-height: 20px;
}

.UiInput-module_ui-input__input_6JYLR__Main::placeholder {
  color: var(--input-text-empty);
  opacity: 1;
}

.UiInput-module_ui-input__input_6JYLR__Main:-ms-input-placeholder {
  color: var(--input-text-empty);
}

.UiInput-module_ui-input__input_6JYLR__Main::-moz-placeholder {
  color: var(--input-text-empty);
}

.UiInput-module_ui-input__input_6JYLR__Main:hover, .UiInput-module_ui-input__input_6JYLR__Main:focus {
  border-color: var(--input-hover-focus);
}

.UiInput-module_ui-input__input-icon_8pagG__Main {
  position: absolute;
  top: calc(50% - .6em);
  left: 10px;
}

.UiInput-module_ui-input__input-icon_8pagG__Main svg {
  fill: var(--icons-gray-dark);
}

.UiInput-module_ui-input__input-icon--search_c1-Jo__Main {
  left: auto;
  right: 10px;
}

.UiInput-module_ui-input__input-icon--hidden_tGK3Q__Main {
  display: none;
}

.UiInput-module_ui-input__label-text_FAMYg__Main {
  color: var(--input-optional-text);
  padding-bottom: 5px;
}

.UiInput-module_ui-input__button_wYlcF__Main {
  background: inherit;
  cursor: pointer;
  border: none;
  position: absolute;
  bottom: 3px;
  right: 10px;
}

.UiInput-module_ui-input__button_wYlcF__Main svg {
  fill: var(--icons-primary);
}

.UiInput-module_ui-input__error_USst-__Main {
  display: none;
}

.UiInput-module_ui-input__error--shown_Eo-Xp__Main {
  color: var(--input-error);
  padding-top: 5px;
  display: flex;
}

